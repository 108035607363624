import React, { useEffect } from 'react';
import { GeoJSON, Popup } from 'react-leaflet';
import { formatAngka } from '../helper/GlobalFunction';

const GeoPop = (e) => {
  console.log("geo pop", e)
  return (
    <Popup>Hello world</Popup>
  )
}

const GeoJSONLayer = ({ data, type_data }) => {
  const style = {
    fillColor: 'transparent',
    weight: 2,
    opacity: 1,
    color: (type_data == 'desa'?'coral':'#000'),
    fillOpacity: 0.7,
  };


  useEffect(() => {
  console.log('data geo', data)
  },[])

  return (
  <GeoJSON 
  data={data} 
  // style={style}
  style={(feature) => {
    const color = feature.label_color; // get the color property of the feature
    return {
      color: color, // set the stroke color of the feature
      fillColor: color, // set the fill color of the feature
      weight: 2, // set the weight of the stroke
      opacity: 1, // set the opacity of the feature
      fillOpacity: 0.5 // set the opacity of the fill
    };
  }}
  onEachFeature={(feature, layer) => {
    const popupContent = `
      <p><strong>${feature.properties.nmprov}</strong></p>
      <p>Kabupaten: ${feature.properties.nmkab}</p>
      <p>Kecamatan: ${feature.properties.nmkec}</p>
      <p>Jumlah: ${ formatAngka(feature.counting,'.')}</p>
      <p>Jumlah Kursi: ${feature.jumlah_kursi}</p>
    `;
    layer.bindPopup(popupContent);
  }}
  eventHandlers={{
    click: (e) => {
    
      var display = `
      Provinsi: ${e.layer.feature.properties.nmprov} \n
      Kabupaten: ${e.layer.feature.properties.nmkab} \n
      Kecamatan: ${e.layer.feature.properties.nmkec} \n
      `
      // alert(display);
    },
  }}

  // children={ () =>  <Popup>Hello world</Popup>}
  >

  </GeoJSON>
  )
  // return <GeoJSON data={data} style={style} />;
  // return <GeoJSON data={data} style={style} />;
};

export default GeoJSONLayer;
