import React, { useEffect, useState } from 'react';
import MapComponent from '../components/MapComponent';
import GeoJSONLayer from '../components/GeoJSONLayer';

// import provinsiData from '../public/data/provinsi.json';
// import kabupatenData from '../public/data/kabupaten.json';
import kecamatanData from '../public/data/kecamatan-murung-raya.json';
import desaData from '../public/data/desa-murung-raya.json';
import { Marker, Popup, Tooltip } from 'react-leaflet';

const MapPage = () => {
  const [kc_data, set_kc_data] = useState({});
  const [show_1,set_show_1] = useState(true);
  // const center = [-6.21462, 106.84513];
  // const zoom = 8;
  const zoom = 9;
  
  // const center = [-0.5109,114.4204];

  const center = [0.029234, 114.223391]
  useEffect( () => {
    console.log('kecamatanData', kecamatanData)
    // set_kc_data(kecamatanData);
  }, [])
  return (
    <div>
      <div className='col-md-3'>
        <div className="form-check" onClick={ () => set_show_1(true)}>
          <input className="form-check-input" type="radio" name="flexRadioDefault" id="flexRadioDefault2" defaultChecked={show_1} />
          <label className="form-check-label" htmlFor="flexRadioDefault2">
            Dengan Map
          </label>
        </div>
        <div className="form-check" onClick={ () => set_show_1(false)}>
          <input className="form-check-input" type="radio" name="flexRadioDefault" id="flexRadioDefault1" />
          <label className="form-check-label" htmlFor="flexRadioDefault1">
            Tanpa Map
          </label>
        </div>
        
      </div>

      <MapComponent center={center} zoom={zoom} show_1={show_1}>
        {/* <GeoJSONLayer data={provinsiData} /> */}
        {/* <GeoJSONLayer data={kabupatenData} /> */}
        <GeoJSONLayer data={kecamatanData} type_data={'kecamatan'} />
        {/* <GeoJSONLayer data={desaData} type_data={'desa'} /> */}

        {kecamatanData.features.map((v,i) => {
          return (
            <>
              <Marker position={v.center_kecamatan.complete}>
                <Popup>
                  A pretty CSS3 popup. <br /> Easily customizable.
                </Popup>
                <Tooltip direction="top" offset={[0, 0]} opacity={1} permanent>{v.properties.nmkec}</Tooltip>
              </Marker> 
            </>

          )
        })}

      </MapComponent>
    </div>
  );
};

export default MapPage;
