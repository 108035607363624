import React from 'react';
import { MapContainer, Marker, Popup, TileLayer } from 'react-leaflet';

const MapComponent = ({ center, zoom, children, show_1, kc_data }) => {
  return (
    <MapContainer center={center} zoom={zoom} 
      // style={{ 
      // width: '100%',
      // height: '69vh',
      // }}
    >
      {
        show_1?
        <TileLayer url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png" />
        :
        <></>
      }
      {/* <Marker position={position}>
        <Popup>
          A pretty CSS3 popup. <br /> Easily customizable.
        </Popup>
      </Marker> */}
      {children}
    </MapContainer>
  );
};

export default MapComponent;
